<template>
    <div>
        <div class="row mx-4">
            <StatsCard 
                :title='$t("Total Kilometers")'
                :amount="dsChallengeStats.current.TotalRepetitions"
                :underTitle='$t("In average")'
                :otherAmount="dsChallengeStats.current.AverageRepetitions"></StatsCard>
            <StatsCard 
                :title='$t("Total Sponsored")'
                :amount="dsChallengeStats.current.TotalSponsored"
                :underTitle='$t("In average")'
                :otherAmount="dsChallengeStats.current.AverageSponsored"></StatsCard>
            <StatsCard 
                :title='$t("Total Sponsors")'
                :amount="dsChallengeStats.current.TotalSponsors"
                :underTitle='$t("In average")'
                :otherAmount="dsChallengeStats.current.AverageAmountOfSponsors"></StatsCard>
        </div>
    </div>
</template>

<script setup>

import { getOrCreateDataObject } from 'o365.vue.ts'
import { $t } from 'o365.vue.ts'
import { ref, watch } from 'vue'
import { defChallengeStats, defGroupsTop5Reps, defGroupsTop5Sponsors, defTopListFunded, defToplistRepititions } from 'c365.fundraising.challenge-statistics.dataObjects.ts'; 
import StatsCard from 'c365.fundraising.challenge-statistics.stats-card.vue'

const hasChallengeStats = ref(false)
const hasTop5Reps = ref(false)

const dsChallengeStats = getOrCreateDataObject(defChallengeStats);
// const dsGroupsTop5Reps = getOrCreateDataObject(defGroupsTop5Reps);
// const dsGroupsTop5Sponsors = getOrCreateDataObject(defGroupsTop5Sponsors);
// const dsTopListFunded = getOrCreateDataObject(defTopListFunded);
// const dsToplistRepititions = getOrCreateDataObject(defToplistRepititions);

// dsChallengeStats.on("DataLoaded", () => {
    // console.log("DataLoaded dsChallengeStats")
    // hasChallengeStats.value = true;
// });

// dsGroupsTop5Reps.on("DataLoaded", () => {
        // console.log("DataLoaded dsGroupsTop5Reps")
    // hasTop5Reps.value = true; 
// });

// watch([hasChallengeStats, hasTop5Reps], () => {
    // // if(hasChallengeStats.value && hasTop5Reps.value)
        // // buildChart();
    // console.log("hellu")
// })

dsChallengeStats.load();
// dsGroupsTop5Reps.load()
// dsGroupsTop5Sponsors.load();
// dsTopListFunded.load();
// dsToplistRepititions.load();

function buildChart() {
    const top5Reps = dsGroupsTop5Reps.data;
    const repLabel = dsChallengeStats.current.RepetitionLabel;
    const repLableToUpper = repLabel.charAt(0).toUpperCase().slice(1);

    var dataPoints = [];

    top5Reps.forEach(function (obj, index) {
        dataPoints[index] = {
            name: obj.Name,
            data: [obj["TotalRepetitions"]],
            color: obj["ColorCode"]
        };
    });

    console.log(dataPoints, "dataPoints")

    Highcharts.chart('chartContainer', {
        chart: {
            type: 'column'
        },
        title: {
            text: $t(`Top 5 Groups:`)
        },
        xAxis: {
            categories: [' '],
            title: {
                text: repLableToUpper,
                align: 'high'
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: repLableToUpper,
                align: 'high'
            },
            labels: {
                overflow: 'justify'
            }
        },
        tooltip: {
            valueSuffix: repLableToUpper,
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true
                }
            }
        },
        credits: {
            enabled: false
        },
        series: dataPoints
    });
}

const getIcon = (index) => {
    const iconNames = ['2', '3', '4', '5'];

    return iconNames[index - 1];
};

</script>

<style scoped>
card {
    margin-right: 50px;
}
</style>